import { ArrowUpIcon } from "lucide-react";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

export default function Footer() {
  const auth = useAuth0();

  return (
    <footer className="bg-white lg:ml-72">
      <div className="px-6 pt-20 pb-10 mx-auto overflow-hidden max-w-7xl sm:pt-24 sm:pb-12 lg:px-8">
        <nav aria-label="Footer">
          {auth.isAuthenticated && (
            <div className="flex items-center justify-center mb-8">
              <Link
                to="/dashboard/feedback"
                className="text-lg leading-6 text-gray-600 hover:text-gray-900 font-medium"
              >
                Feedback & Feature Requests
              </Link>
            </div>
          )}
          <div className="flex items-center flex-wrap justify-center w-full gap-8">
            <Link
              to="/help-and-info"
              className="text-lg leading-6 text-gray-600 hover:text-gray-900"
            >
              Help & Info
            </Link>
            <Link
              to="/fee-structure"
              className="text-lg leading-6 text-gray-600 hover:text-gray-900"
            >
              Fee Structure
            </Link>
            {/* <Link
              to="/changelog"
              className="text-lg leading-6 text-gray-600 hover:text-gray-900"
            >
              Changelog
            </Link> */}
            <Link
              to="/faqs"
              className="text-lg leading-6 text-gray-600 hover:text-gray-900"
            >
              FAQs
            </Link>
            <Button
              variant="ghost"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <ArrowUpIcon />
            </Button>
          </div>
        </nav>
      </div>
    </footer>
  );
}
